const ActionTypes = {
   SET_USER: "SET_USER",
   REMOVE_USER: "REMOVE_USER",
   GET_QUESTIONS_AGE_WISE: "GET_QUESIONS_AGE_WISE",
   CURRENT_QUESTION: "CURRENT_QUESTION",
   FIRST_QUESTION_ANSWERED: "FIRST_QUESTION_ANSWERED",
   QUESTIONS_ANSWERED: "QUESTIONS_ANSWERED",
   CLEAR_ALL: "CLEAR_ALL",
   TOGGLE_LOADING: "TOGGLE_LOADING"
}

export default ActionTypes